import {AsyncTypeahead} from "react-bootstrap-typeahead";
import React, { useEffect, useState, Fragment} from "react";
import {CloseButton, Table} from "react-bootstrap";
import PlayerSearch2 from "./PlayerSearch2";
import Editable from "react-bootstrap-editable/dist/editable";

export default class TeamView2 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            playerArray: [],
            tempTeam: [],
            teamName: ''
        }

        this.loadTeam(props.teamId)
        this.fuckingAround = this.fuckingAround.bind(this)
        this.renderTeam = this.renderTeam.bind(this)
        this.removePlayer = this.removePlayer.bind(this)
        this.renameTeam = this.renameTeam.bind(this)

    }




    loadTeam(teamId) {
        this.state.isLoading = true
        fetch(`${process.env.REACT_APP_PS_API_URL}/api/players?teamId=${teamId}`)
            .then((response) => response.json())
            .then(({players,teamName}) => {


                const playerArray = players.map((i) => ({
                    name: i.name,
                    excelId: i.excelId
                }));

                this.state.playerArray = playerArray
                this.state.isLoading = false

                this.state.tempTeam = playerArray
                this.state.teamName = teamName
                this.forceUpdate()

            });

        console.log(JSON.stringify(this.state.playerArray))

    }

    removePlayer(player) {
        console.log('removing ' + JSON.stringify(player))

        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json'},
        };

        fetch(`${process.env.REACT_APP_PS_API_URL}/api/removePlayerFromTeam?playerId=${player.excelId}&teamId=${this.props.teamId}`, requestOptions)
            .then(response => response)
            .then(data => { this.fuckingAround() }
            );


    }

    renameTeam(newName) {
        console.log('renaming ' + JSON.stringify(newName))

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json'},
        };

        fetch(`${process.env.REACT_APP_PS_API_URL}/api/renameTeam/${this.props.teamId}/${newName}`, requestOptions)
            .then(response => response)
            .then(data => { this.fuckingAround() }
            );


    }


    renderTeam(player, index) {
        return (
            <tr key={index}>
                <td onClick={() => this.setState({ editingPlayer: index })}>
                    {this.state.editingPlayer === index ? <PlayerSearch2 handler={this.fuckingAround} teamId={this.props.teamId} index={index} /> : player.name}
                </td>
                <td>
                    <button style={{opacity: 0, cursor: 'default'}} disabled></button>
                </td>
            </tr>
        )
    }
    fuckingAround() {
        console.log('been fucking around this town')
        // this.setState({
        //     playerArray: [{name: "Tiger Woods"}, {name: "Nicholas Cage"}, {name: "Fooby Foops"}]
        // })
        this.loadTeam(this.props.teamId)
        this.setState({
            playerArray: this.state.tempTeam
        })

        this.forceUpdate()

    }
    render() {
        console.dir(this.state.playerArray)

        // Calculate the number of empty rows needed
        const emptyRows = Math.max(0, 12 - this.state.playerArray.length);

        // Create an array with the required number of empty rows
        const emptyRowsArray = Array(emptyRows).fill({});

        // Combine player array with empty rows
        const combinedArray = this.state.playerArray.concat(emptyRowsArray);

        return (
            <div style={{width: "300px"}}>
                <h1><Editable initialValue={this.state.teamName} onSubmit={this.renameTeam} showText isValueClickable="true"/></h1>
                <Table variant="dark">
                    <thead>
                    <tr>
                        <th>Player Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {combinedArray.map((player, index) => this.renderTeam(player, index))}
                    </tbody>
                </Table>
            </div>
        );
    }

};
