import {AsyncTypeahead} from "react-bootstrap-typeahead";
import React, { useEffect, useState, Fragment} from "react";


export default class PlayerSearch2 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading : false,
            options: []
        }

        this.handleSearch = this.handleSearch.bind(this)
        this.handleSelection = this.handleSelection.bind(this)
    }


    handleSearch(query) {
        //this.state.isLoading = true
        this.setState({isLoading: true})

        const mQuery = {
            "q": query
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer 7HH5yxsC7U0Jd1dA',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(mQuery)
        };


        fetch(`${process.env.REACT_APP_PS_API_URL}/search-api/indexes/golfers/search`, requestOptions)
            .then((response) => response.json())
            .then(({hits}) => {
                const options = hits.map((i) => ({
                    name: i.name,
                    excelId: i.excelId,
                }));


                this.state.isLoading = false;
                //this.state.options = options; <-- this made it update a keystroke too late
                this.setState({options: options})

            });

        this.setState()
        this.render()

    };

    handleSelection(selection) {
        selection[0]['position'] = this.props.index
        //alert('selected ?? ' + JSON.stringify(selection) + ' index: ' + this.props.index)
        // call the add to team API.

        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify(selection)
        };

        fetch(`${process.env.REACT_APP_PS_API_URL}/api/addPlayerToTeam/${this.props.teamId}`, requestOptions)
            .then(response => response)
            .then(data => { console.log(data); this.props.handler()}
            );

    }

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    filterBy() {
        return true
    }


    render() {
        return (
            <AsyncTypeahead
                filterBy={this.filterBy}
                id="async-example"
                isLoading={this.props.isLoading}
                labelKey="name"
                minLength={1}
                onSearch={this.handleSearch}
                onChange={this.handleSelection}
                options={this.state.options}
                placeholder="Search for a Player..."
                renderMenuItemChildren={(option, props) => (
                    <Fragment>
                        <span>{option.name}</span>
                    </Fragment>
                )}
            />
        );
    }

};