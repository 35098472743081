import './App.css';
import Zap from "./Zap.js";
import React, {useEffect, useState} from "react";



function App() {

    // State to hold the leagueId
    const [leagueId, setLeagueId] = useState('');

    useEffect(() => {
        // Use URLSearchParams to parse the query parameters
        const queryParams = new URLSearchParams(window.location.search);
        const id = queryParams.get('leagueId'); // Assuming the URL parameter is named 'leagueId'

        if (id) {
            setLeagueId(id);
        }
    }, [window.location.search]); // Re-run effect if search string changes

    console.log('leagueId: ' + leagueId)

    const { REACT_APP_PS_API_URL } = process.env;

    if (process.env.REACT_APP_PS_API_URL == "undefined") {
        process.env.REACT_APP_PS_API_URL = ''
    }

    return (
        <div>
            {leagueId ? <Zap leagueId={leagueId} /> : <p>Loading league information...</p>}
        </div>
    )
}

export default App;
