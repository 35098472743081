import './App.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import React from "react";
import TeamView2 from "./TeamView2";

export default class Zap extends React.Component {

    constructor(props) {
        super(props)

        console.log(props.leagueId)

        console.log(process.env.REACT_APP_PS_API_URL)

        // this would be a good place to pre-load the teams

        this.state = {
            suggestions: [],
            text : '',
            isLoading : false,
            options: [],
            teamIds: [],
            leagueName: '',
            teamsPerLeague: 0,
            playersPerTeam: 0,
            playerArray: []
        }

    }

    componentDidMount() {
        this.loadLeague(this.props.leagueId)
        this.loadTeams(this.props.leagueId)
    }

    useQuery() {
        return new URLSearchParams(window.location.search);
    }

    async loadLeague(leagueId) {
        this.setState({isLoading: true})

        const response = await fetch(`${process.env.REACT_APP_PS_API_URL}/api/league?leagueId=${leagueId}`)




        fetch(`${process.env.REACT_APP_PS_API_URL}/api/league?leagueId=${leagueId}`)
            .then((response) => response.json())
            .then(({league}) => {
                this.setState({leagueName: league.leagueName, teamsPerLeague: league.teamsPerLeague, playersPerTeam: league.playersPerTeam})

                this.state.isLoading = false;
            });

    }

    loadTeams(leagueId) {



        //this.state.isLoading = true
        this.setState({isLoading: true})

        fetch(`${process.env.REACT_APP_PS_API_URL}/api/leagueTeams?leagueId=${leagueId}`)
            .then((response) => response.json())
            .then(({teams}) => {
                const teamIds = teams.map((i) => ({
                    teamId: i.teamId
                }));

                this.setState({teamIds: teamIds})
                ///this.state.teamIds = teamIds;
                this.state.isLoading = false;

            });
    }

    render() {

        if (!this.state.teamIds) {
            console.log('there are no teamIds - never happens')
            return <div />
        }

        // pad the teamIds array with empty strings to make it the length of
        // teamsPerLeague
        const paddedTeamIds = this.state.teamIds
        for (let i = this.state.teamIds.length; i < this.state.teamsPerLeague; i++) {
            paddedTeamIds.push('')
        }



        console.dir(this.state.teamIds)


        return (



            <div>

                <div class="row">
                    {paddedTeamIds.map((teamId) => (
                        <div class="col-sm">
                            <TeamView2 teamId={teamId.teamId} teamSize={this.state.playersPerTeam}/>
                        </div>
                    ))}
                </div>




            </div>


            // add a container containing 2 dropdowns, one with a label "Number of Teams" that allows a choice of 8, 10 or 12.
            // the other dropdown will be "Number of Players per Team" that allows a choice of 10, 11 or 12.


        );

    }

    renderTeam(player, index) {
        return (
            <tr key={index}>
                <td>{player.name}</td>
            </tr>
        )
    }
}